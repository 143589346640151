import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { useDispatch } from "react-redux";
import Spinner from "../common/Spinner";
import { postNotification } from "../../services/AuthService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { Button, Card, Col, Nav, Tab } from "react-bootstrap";
import moment from "moment";
import Pagination from "../common/Pagination";
import profile from "../../images/blank-profile-picture.webp";
import { getAllUsers } from "../../services/User/UserService";
export default function Notifications(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  let errorsObj = { title: "", body: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [requests, setRequests] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("daily");
  const [sort, setSort] = useState("desc");
  const [initialFetch, setInitialFetch] = useState(true);
  const limit = 10;
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllUsers(
        currentPage,
        limit,
        search,
        status,
        sort
      );
      console.log(response.data, "users");
      setRequests(response?.data?.result?.docs);
      const total = response.data.result?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.result?.total);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  //// function to post data ///
  const onSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (title.trim() === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (body.trim() === "") {
      errorObj.body = "Body is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await postNotification(title, body);
      notifyTopRight(response.data?.responseMessage);
      setTitle("");
      setBody("");

      setLoader(false);
    } catch (error) {
      notifyError(error?.response?.data?.responseMessage);
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);
  return (
    <div>
      <PageTitle activeMenu="Push Notification" motherMenu="Notifications" />

      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="home">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="home">Notifications</Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link eventKey="second">Push Notifications</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className="pt-4">
            <Tab.Pane eventKey="home">
              <Col>
                {requests?.map((item, i) => (
                  <Card>
                    <Card.Body>
                      <div>
                        <h3 className="text-black">New User Registered!</h3>
                        <p className="fs-14">
                          Description: Registered on{" "}
                          {moment().format("DD/MM/YYYY")}.
                        </p>

                        <div
                          className="d-flex align-items-center justify-content-start"
                          // onClick={() => props.history.push("/user-profile")}
                          style={{  gap: "5rem" }}
                        >
                          <img
                            src={profile}
                            alt=""
                            width={50}
                            height={50}
                            style={{ borderRadius: "50%" }}
                          />
                          {/* <div className="pl-5"> */}
                          <p className="">{item?.name}</p>
                          <p className="">{item?.email}</p>
                          <p className="">
                            +{item?.countryCode + " " + item?.number}
                          </p>
                          {/* </div> */}
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                ))}
                {requests?.length === 0 && !loader ? (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data not found!
                  </div>
                ) : (
                  ""
                )}
                {listLength > 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Showing {currentPage * limit + 1} to{" "}
                        {listLength > (currentPage + 1) * limit
                          ? (currentPage + 1) * limit
                          : listLength}{" "}
                        of {listLength} entries
                      </div>
                      <Pagination
                        pageCount={pageCount}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="second">
              <div className="col-lg-8 col-md-7 ">
                <div className="authincation-content text-black p-5">
                  <div className="mb-4">
                    <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Title</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setErrors({ ...errors, title: "" });
                        }}
                        placeholder="Enter title"
                      />
                      {errors.title && (
                        <div className="text-danger fs-12">{errors.title}</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label className="mb-2 ">
                        <strong className="">Body</strong>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={body}
                        onChange={(e) => {
                          setBody(e.target.value);
                          setErrors({ ...errors, body: "" });
                        }}
                        placeholder="Write something here.."
                      />
                      {errors.body && (
                        <div className="text-danger fs-12">{errors.body}</div>
                      )}
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {/* <Reason
        show={show}
        id={id}
        table={getTableData}
        onHide={() => setShow(false)}
      /> */}
      {loader && <Spinner />}
    </div>
  );
}
