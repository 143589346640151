export const GET_USERS = 'admin/userList';

export const DELETE_USERS = 'admin/auth/userDelete';

export const BLOCK_USERS = 'admin/enableDisabledUser';

export const APPROVE_USER_DETAILS = 'admin/auth/userApproved';

export const EDIT_USER = 'admin/editUser';

export const UPLOAD_FILE = 'admin/uploadFile';
