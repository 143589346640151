import React, { useEffect, useState } from "react";
import { Card, Tab, Nav, Dropdown } from "react-bootstrap";
import VideoCard from "../components/VideoCard";
import PageTitle from "../layouts/PageTitle";
import { actionPosts, getAllPosts } from "../../services/Videos/VideoService";
import Spinner from "../common/Spinner";
import ViewVideosInfo from "../modal/ViewVideosInfo";
// import AddPost from "../modal/AddPost";

export default function WinnerVideoManagement() {
  const [selectedTab, setSelectedTab] = useState("approved");
  const [addPostModal, setAddPostModal] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState({});
  const [videoData, setVideoData] = useState({});
  const limit = 10;

  const tabData = [
    {
      name: "Active",
      icon: "approved",
      content:
        "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove.",
    },
    {
      name: "Inactive",
      icon: "inactive",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },

    {
      name: "Deleted",
      icon: "deleted",
      content:
        "Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache cliche tempor.      ",
    },
  ];

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllPosts(
        currentPage,
        limit,
        selectedTab,
        "true"
      );
      console.log(response.data, "users");
      setData(response?.data?.result?.docs);
      const total = response.data.result?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.result?.total);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  useEffect(() => {
    getTableData();
  }, [currentPage, selectedTab]);
  return (
    <div>
      <PageTitle activeMenu="Videos List" motherMenu="All Video" />
      <div className="col-xl-12 pr-0">
        <Card className="post-card">
          <Card.Body>
            <div className="custom-tab-1">
              <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                <Nav as="ul" className="nav-tabs">
                  {tabData.map(
                    (data, i) =>
                      i !== tabData.length && (
                        <Nav.Item
                          as="li"
                          key={i}
                          onClick={() => setSelectedTab(data.icon)}
                        >
                          <Nav.Link
                            eventKey={data.name.toLowerCase()}
                            className="d-flex align-items-center tab-section"
                          >
                            <li className="m-0 pr-3">{data.name}</li>
                            {/* <i
                            className={`ti-${i === 2 ? "email" : data.icon}`}
                          /> */}
                          </Nav.Link>
                        </Nav.Item>
                      )
                  )}
                </Nav>
                {loader ? (
                  <Spinner />
                ) : (
                  <Tab.Content className="pt-4">
                    {selectedTab === "approved" && (
                      <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ gap: "10px" }}
                      >
                        {data?.map((item, i) => (
                          <VideoCard
                            options={["View", "Inactive", "Delete"]}
                            id={item?._id}
                            data={item}
                            setViewModal={setViewModal}
                            setVideoData={setVideoData}
                            apifunc={actionPosts}
                            table={getTableData}
                            winner={"true"}
                          />
                        ))}
                      </div>
                    )}
                    {selectedTab === "inactive" && (
                      <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ gap: "10px" }}
                      >
                        {data?.map((item, i) => (
                          <VideoCard
                            id={item?._id}
                            data={item}
                            options={["View", "Active", "Delete"]}
                            apifunc={actionPosts}
                            table={getTableData}
                            winner={"true"}
                          />
                        ))}
                      </div>
                    )}
                    {selectedTab === "deleted" && (
                      <div
                        className="d-flex flex-wrap justify-content-start"
                        style={{ gap: "10px" }}
                      >
                        {data?.map((item, i) => (
                          <VideoCard
                            id={item?._id}
                            data={item}
                            setViewModal={setViewModal}
                            setVideoData={setVideoData}
                            options={["View"]}
                          />
                        ))}
                      </div>
                    )}
                    {data?.length === 0 && !loader ? (
                      <div className="justify-content-center d-flex my-5 ">
                        Sorry, Data Not Found!
                      </div>
                    ) : (
                      ""
                    )}
                  </Tab.Content>
                )}
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      </div>
      {viewModal && (
        <ViewVideosInfo
          show={viewModal}
          data={videoData}
          onHide={() => setViewModal(false)}
        />
      )}
    </div>
  );
}
