import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { imageUpload } from "../../services/AuthService";
import { notifyError, notifyTopRight } from "../common/Toaster";
import PhoneInput from "react-phone-input-2";
import { editUser } from "../../services/User/UserService";
import dummyPic from "../../images/blank-profile-picture.webp";

export default function EditUser({ show, data, table, onHide }) {
  console.log(data, "data");
  // Modal box
  let errorsObj = {
    email: "",
    name: "",
    profilePic: "",
    countryCode: "",
    number: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    profilePic: "",
    countryCode: "",
    number: "",
  });

  const [initialData, setInitialData] = useState({
    email: "",
    name: "",
    profilePic: "",
    countryCode: "",
    number: "",
  });

  console.log(formData, "formData");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleImageChange = async (e) => {
    const selectedFile = e.target.files[0];
    console.log(selectedFile, "image before upload");

    if (selectedFile) {
      try {
        const response = await imageUpload(selectedFile);
        console.log(response, "image location");
        setFormData({
          ...formData,
          profilePic: response.data?.result?.url,
        });
        setErrors((prevErrors) => ({ ...prevErrors, profilePic: "" }));
      } catch (error) {
        console.log(error, "image upload error");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };

    if (formData?.email?.trim() === "") {
      errorObj.email = "This field is required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }

    // Filter out unchanged data
    const changedData = {};
    for (const key in formData) {
      if (formData[key] !== initialData[key]) {
        changedData[key] = formData[key];
      }
    }
    console.log(changedData, "changedData");

    try {
      const response = await editUser(changedData, data?._id);
      onHide();
      table();
      notifyTopRight(response?.data?.responseMessage);
    } catch (error) {
      console.log(error.response, "change password error");
      notifyError(error?.response?.data?.responseMessage);
    }
  };

  const handleCancel = () => {
    onHide();
  };

  useEffect(() => {
    const initialFormData = {
      name: data?.name,
      email: data?.email,
      number: data?.number,
      profilePic: data?.profilePic,
      countryCode: "+" + data?.countryCode,
    };
    setFormData(initialFormData);
    setInitialData(initialFormData);
  }, [data]);

  return (
    <>
      <Modal className="modal fade" show={show} centered onHide={onHide}>
        <div className="" role="document">
          <div className="">
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Edit User</h4>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    {/* <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Profile Pic
                      </label>
                      <div className="mb-2">
                        <img
                          src={
                            formData.profilePic ? formData.profilePic : dummyPic
                          }
                          width={60}
                          height={60}
                        />
                      </div>
                      <div className="contact-name">
                        <input
                          type="file"
                          className="form-control"
                          name="profilePic"
                          onChange={handleImageChange}
                        />
                        {errors.profilePic && (
                          <div className="text-danger fs-12">
                            {errors.profilePic}
                          </div>
                        )}
                      </div>
                    </div> */}

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                        />
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="name"
                          value={formData.name}
                          onChange={handleInputChange}
                        />
                        {errors.name && (
                          <div className="text-danger fs-12">{errors.name}</div>
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label className="text-black font-w500">
                        Phone Number
                      </label>
                      <div className="contact-name d-flex justify-content-start">
                        <PhoneInput
                          country={"us"}
                          enableSearch={true}
                          value={formData.countryCode}
                          onChange={(phone) =>
                            setFormData({ ...formData, countryCode: phone })
                          }
                          inputProps={{
                            readOnly: true, // Disable manual typing
                          }}
                        />
                        <input
                          type="tel"
                          className="form-control extra-padding"
                          name="number"
                          value={formData.number}
                          onChange={handleInputChange}
                          pattern={"[0-9]*"}
                          onInput={(e) =>
                            (e.target.value = e.target.value.replace(/\D/, ""))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="btn btn-secondary"
                >
                  <i className="flaticon-delete-1"></i> Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
