/// auth service

export const LOGIN = 'admin/login';

export const CHANGE_PASSWORD = 'admin/changePass';

export const LOGOUT = 'admin/logout';

export const DASHBOARD = 'admin/dashboard';

export const GET_CHART = 'admin/usersGraphs';

export const GET_POSTS_CHART = 'admin/postsGraphs';

export const FILE_UPLOAD = 'admin/uploadFile';

export const POST_NOTIFICATION = 'admin/notification';
