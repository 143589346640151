import React from "react";
import PageTitle from "../layouts/PageTitle";
import { Badge, Card, Dropdown, Table } from "react-bootstrap";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { useState } from "react";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { useEffect } from "react";
import profile from "../../images/blank-profile-picture.webp";
import { getAllUsers } from "../../services/User/UserService";
import moment from "moment";
import {
  getBannerList,
  updateBanner,
} from "../../services/Videos/VideoService";
import AddBanner from "../modal/AddBanner";

export default function WebsiteManagement() {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [sort, setSort] = useState("desc");
  const [initialFetch, setInitialFetch] = useState(true);
  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getBannerList(currentPage, limit, search);
      console.log(response.data, "users");
      setUsers(response?.data?.result?.docs);
      const total = response.data.result?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.result?.total);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  const onAction = async (id, status) => {
    setLoader(true);
    try {
      const response = await updateBanner(id, status);
      notifyTopRight(response.data?.responseMessage);
      getTableData();
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      notifyError(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage]);

  let timeoutId;

  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // Create a debounced version of the handleFetch function
  const debouncedHandleFetch = debounce(getTableData, 500); // Adjust the delay as needed

  useEffect(() => {
    if (initialFetch === false) {
      // Reset current page when search or status changes
      setCurrentPage(0);
      // Trigger the debounced handleFetch function when search or status changes
      debouncedHandleFetch();
      // Cleanup function
      return () => {
        // Clear any pending API calls if component unmounts or search/status changes again
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  return (
    <div>
      {" "}
      <PageTitle activeMenu="Website" motherMenu="Website Management" />
      <Card>
        <Card.Header className="d-block">
          <div className="d-flex justify-content-between align-items-center">
            <div className="col-4" style={{ flexGrow: 1 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="input-group border bg-white input-group-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <input
                    type="text"
                    name="table_search"
                    className="form-control float-right border-0 search-input"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={getTableData}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setShow(true)}
              >
                Add New
              </button>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="pt-1 pb-1">
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>Video Thumbnail</strong>
                  </th>
                  <th>
                    <strong>added on</strong>
                  </th>

                  <th>
                    <strong>STATUS</strong>
                  </th>
                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td style={{maxWidth:"250px"}}>{item?.name}</td>
                    <td style={{maxWidth:"250px"}}>{item?.fileName}</td>

                    <td>{moment(item?.createdAt).format("DD/MM/YYYY")}</td>

                    <td>
                      {item?.active ? (
                        <Badge variant="success light">Active</Badge>
                      ) : (
                        <Badge variant="danger light">Inactive</Badge>
                      )}
                    </td>
                    <td>
                      {!item?.active ? (
                        <button
                          type="button"
                          className="btn btn-success p-1 fs-12"
                          onClick={() => onAction(item?._id, true)}
                        >
                          Activate
                        </button>
                      ) : (
                        "NA"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {users?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
          {users?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
      {show && (
        <AddBanner
          show={show}
          table={getTableData}
          onHide={() => setShow(false)}
        />
      )}
    </div>
  );
}
