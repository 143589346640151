import axiosInstance from "../AxiosInstance";
import {
  APPROVE_USER_DETAILS,
  BLOCK_USERS,
  DELETE_USERS,
  EDIT_USER,
  GET_USERS,
  GET_USER_DETAILS,
  POST_USER_DETAILS,
  UPLOAD_FILE,
  VERIFY_USER_DETAILS,
} from "./UserApiEndPoints";
// export function getAllUsers(currentPage, limit, search) {
//   return axiosInstance.get(
//     GET_USERS + `?page=${currentPage}&limit=${limit}&search=${search}`
//   );
// }
export function getAllUsers(currentPage, limit, search, status, sort) {
  const searchQuery = search ? `&search=${search}` : ""; // Conditionally add search query

  return axiosInstance.get(
    GET_USERS +
      `?page=${currentPage}&limit=${limit}${searchQuery}&status=${status}&sort=${sort}`
  );
}

export function deleteUser(id) {
  return axiosInstance.delete(DELETE_USERS + `?id=${id}`);
}

export function blockUser(id) {
  return axiosInstance.put(BLOCK_USERS + `?_id=${id}`);
}

export function approveUser(userId) {
  return axiosInstance.post(APPROVE_USER_DETAILS, userId);
}
export function editUser(changedData,id) {
  return axiosInstance.put(EDIT_USER +`?userId=${id}`, changedData);
}

export function imageUpload(file) {
  return axiosInstance.post(UPLOAD_FILE, file);
}
