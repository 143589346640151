import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dropdown, Modal } from "react-bootstrap";
import playBtn from "../../images/svg/Group 5606.svg";
export default function ViewVideosInfo({ show, data, onHide }) {
  const [play, setPlay] = useState(false);
  console.log(data, "data");
  return (
    <Modal className="modal fade" show={show} centered onHide={onHide}>
      <div className="" role="document">
        <div className="d-flex justify-content-start" style={{ gap: "10px" }}>
          <div className="reel-card" style={{ width: "310px" }}>
            {play ? (
              <video width="100%" height="372" controls autoPlay>
                <source src={data?.file} type={data?.fileType} />
              </video>
            ) : (
              <img
                className="video-player"
                // src={`https://source.unsplash.com/250x350/?${1}`}
                src={data?.fileThumbnail}
                alt=""
              />
            )}
           
            {!play && (
              <div className="user-info justify-content-between w-100">
                <div>
                  <span className="username">{data?.userId?.name}</span>
                </div>
                <img
                  src={playBtn}
                  onClick={() => setPlay(true)}
                  width={30}
                  className="pointer"
                />
              </div>
            )}
          </div>
          <div className="" >
            <h3>{data?.userId?.name}</h3>
            <p className="mb-0" style={{maxWidth:"180px",overflowWrap: "break-word"}}>{data?.userId?.email}</p>
            <p className="mb-0">
              +{data?.userId?.countryCode?data?.userId?.countryCode:data?.countryCode} {data?.userId?.number?data?.userId?.number:data?.number}
            </p>
            <p className="mb-0">
              <b>Date of birth - </b>
              {data?.dob}
            </p>
            <p className="mb-0">
              <b>Address - </b>
              {data?.address?.street},{data?.address?.line2}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}
