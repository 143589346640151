export const GET_POSTS = "admin/listPosts";

export const PUT_POSTS = "admin/approveOrRejectPosts";

export const MARK_POSTS = "admin/markWinner";

export const GET_BANNERS = "admin/bannerList";

export const ADD_BANNER = "admin/addBanner";

export const PUT_BANNER = "admin/updateBanner";

export const GET_REPORTS = "admin/reports";

////////////////====================== Reason ===================////////////////////

export const GET_REASONS = "admin/reasonsList";

export const PUT_REASON = "admin/updateReason";

export const POST_REASON = "admin/addReasons";

export const DELETE_REASON = "admin/deleteReason";

//////////////////////////==================hashtags===================//////////////////////////

export const GET_TAGS = "admin/tagsList";

export const POST_TAGS = "admin/addTag";

export const DELETE_TAGS = "admin/deleteTag";

export const ASSIGN_TAGS = "admin/assignTags";

