import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import {
  CHANGE_PASSWORD,
  DASHBOARD,
  FILE_UPLOAD,
  GET_CHART,
  GET_POSTS_CHART,
  LOGIN,
  LOGOUT,
  POST_NOTIFICATION,
} from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";
import { notifyError } from "../jsx/common/Toaster";

export function signUp(email, password) {
  //axios call

  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return axiosInstance.post(LOGIN, postData);
}

export function dashboard() {
  return axiosInstance.get(DASHBOARD);
}
export async function getChartsData(type, startDate, endDate) {
  let url = GET_CHART;
  // If type is custom, add fromDate and toDate parameters
  if (type === "custom") {
    url += `?type=${type}&fromDate=${startDate}&toDate=${endDate}`;
  } else {
    url += `?type=${type}`;
  }
  const response = await axiosInstance.get(url);
  return response;
}

export async function getPostsChartData(type, startDate, endDate) {
  let url = GET_POSTS_CHART;
  // If type is custom, add fromDate and toDate parameters
  if (type === "custom") {
    url += `?type=${type}&fromDate=${startDate}&toDate=${endDate}`;
  } else {
    url += `?type=${type}`;
  }

  const response = await axiosInstance.get(url);
  return response;
}
export async function imageUpload(file) {
  console.log("original", file);
  // const postData = {
  //   file,
  // };
  const formdata = new FormData();
  formdata.append("file", file, file?.name);
  const response = await axiosInstance.post(FILE_UPLOAD, formdata);
  return response;
}
export function changePasswoard(oldPassword, newPassword) {
  const postData = {
    oldPassword,
    newPassword,
  };

  return axiosInstance.put(CHANGE_PASSWORD, postData);
}

export function postNotification(title, description) {
  const postData = {
    title: title,
    body: description,
  };

  return axiosInstance.post(POST_NOTIFICATION, postData);
}
export function logOut() {
  return axiosInstance.post(LOGOUT, { test: "" });
}

export function formatError(errorResponse) {
  notifyError(errorResponse);
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("comedyToken", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("comedyToken");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    history.push("/");
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}
