import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

const BarChart1 = ({ chartData }) => {
  console.log(chartData, "chartDataprops data");
  // console.log([...chartData?.labels], "array data");
  // console.log([...chartData?.data], "array data 2");

  const [data, setData] = useState({
    defaultFontFamily: "Poppins",
    labels: [...chartData?.labels],
    datasets: [
      {
        label: "Dataset",
        data: [...chartData?.data],
        borderColor: "rgba(64, 24, 157, 1)",
        borderWidth: "0",
        backgroundColor: "#FF3D3D",
      },
    ],
  });

  const [options, setOptions] = useState({
    legend: false,
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          // Change here
          barPercentage: 0.5,
        },
      ],
    },
  });
  useEffect(() => {
    setData({
      labels: [...chartData?.labels],
      datasets: [
        {
          label: "Dataset",
          data: [...chartData?.data],
          borderColor: "rgba(64, 24, 157, 1)",
          borderWidth: "0",
          backgroundColor: "#FF3D3D",
        },
      ],
    });
  }, [chartData]);
  return (
    <>
      <Bar data={data} height={150} options={options} />
    </>
  );
};

export default BarChart1;
