import React, { useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Tab, Nav } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { blockUser, getAllUsers } from "../../services/User/UserService";

import profile from "../../images/blank-profile-picture.webp";
import { notifyError, notifyTopRight } from "../common/Toaster";
import moment from "moment";
import EditUser from "../modal/EditUser";

export default function UserManagement(props) {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("enable");
  const [sort, setSort] = useState("desc");
  const [initialFetch, setInitialFetch] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [oneUserData, setOneUserData] = useState(null);

  const limit = 10;
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getAllUsers(
        currentPage,
        limit,
        search,
        status,
        sort
      );
      console.log(response.data, "users");
      setUsers(response?.data?.result?.docs);
      const total = response.data.result?.total;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.result?.total);
      setInitialFetch(false);
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  ///function to delete data ///
  const onEdit = async (item) => {
    setOneUserData(item);
    setShowModal(true);
  };

  const onAction = async (id) => {
    setLoader(true);
    try {
      const response = await blockUser(id);
      notifyTopRight(response.data?.responseMessage);
      getTableData();
      console.log(response);
    } catch (error) {
      console.log(error.response, "helooooooooo");
      notifyError(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    getTableData();
  }, [currentPage, status, sort]);

  let timeoutId;

  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // Create a debounced version of the handleFetch function
  const debouncedHandleFetch = debounce(getTableData, 500); // Adjust the delay as needed

  useEffect(() => {
    if (initialFetch === false) {
      // Reset current page when search or status changes
      setCurrentPage(0);
      // Trigger the debounced handleFetch function when search or status changes
      debouncedHandleFetch();
      // Cleanup function
      return () => {
        // Clear any pending API calls if component unmounts or search/status changes again
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  return (
    <div>
      <PageTitle activeMenu="Users List" motherMenu="Users" />

      <div className="custom-tab-1">
        <Tab.Container defaultActiveKey="active">
          <Nav as="ul" className="nav-tabs">
            <Nav.Item as="li">
              <Nav.Link eventKey="active" onClick={() => setStatus("enable")}>
                Active
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link
                eventKey="inactive"
                onClick={() => {
                  setSearch("");
                  setStatus("disable");
                }}
              >
                Inactive
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="active">
              <Card>
                <Card.Header className="d-block">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-4" style={{ flexGrow: 1 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className="input-group border bg-white input-group-sm"
                          style={{ borderRadius: "8px" }}
                        >
                          <input
                            type="text"
                            name="table_search"
                            className="form-control float-right border-0 search-input"
                            placeholder="Search By Name/Email/Phone Number"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={getTableData}
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <select
                        className="form-control"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option hidden>Sort By</option>
                        <option value={"desc"}>Recent</option>
                        <option value={"asc"}>Oldest</option>
                      </select>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pt-1 pb-1">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <Table responsive>
                      <thead className="text-black">
                        <tr>
                          <th>
                            <strong>NAME</strong>
                          </th>
                          <th>
                            <strong>EMAIL</strong>
                          </th>
                          <th>
                            <strong>PHONE NUMBER</strong>
                          </th>
                          <th>
                            <strong>Created On</strong>
                          </th>
                          <th>
                            <strong>STATUS</strong>
                          </th>
                          <th>
                            <strong>ACTION</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.map((item, i) => (
                          <tr key={i}>
                            <td>{item?.name}</td>
                            <td>{item?.email}</td>
                            <td>
                              {" "}
                              {item?.countryCode
                                ? "+" + item?.countryCode
                                : ""}{" "}
                              {item?.number}
                            </td>
                            <td>
                              {moment(item?.createdAt).format("DD/MM/YYYY")}
                            </td>

                            <td>
                              {item?.isBlocked ? (
                                <Badge variant="danger light">Inactive</Badge>
                              ) : (
                                <Badge variant="success light">Active</Badge>
                              )}
                            </td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="warning light"
                                  className="light sharp btn btn-warning i-false"
                                >
                                  {svg1}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => onEdit(item)}>
                                    Edit
                                  </Dropdown.Item>
                                  {item?.isBlocked ? (
                                    <Dropdown.Item
                                      onClick={() => onAction(item?._id)}
                                    >
                                      Active
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() => onAction(item?._id)}
                                    >
                                      Inactive
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  {users?.length === 0 && !loader ? (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  ) : (
                    ""
                  )}
                  {users?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-block d-sm-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {currentPage * limit + 1} to{" "}
                          {listLength > (currentPage + 1) * limit
                            ? (currentPage + 1) * limit
                            : listLength}{" "}
                          of {listLength} entries
                        </div>
                        <Pagination
                          pageCount={pageCount}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
          <Tab.Content>
            <Tab.Pane eventKey="inactive">
              <Card>
                <Card.Header className="d-block">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="col-4" style={{ flexGrow: 1 }}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                          className="input-group border bg-white input-group-sm"
                          style={{ borderRadius: "8px" }}
                        >
                          <input
                            type="text"
                            name="table_search"
                            className="form-control float-right border-0 search-input"
                            placeholder="Search By Name/Email/Phone Number"
                            onChange={(e) => setSearch(e.target.value)}
                          />
                          <div className="input-group-append">
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={getTableData}
                            >
                              <i className="fa fa-search" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <select
                        className="form-control"
                        value={sort}
                        onChange={(e) => setSort(e.target.value)}
                      >
                        <option hidden>Sort By</option>
                        <option value={"desc"}>Recent</option>
                        <option value={"asc"}>Oldest</option>
                      </select>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="pt-1 pb-1">
                  {loader ? (
                    <Spinner />
                  ) : (
                    <Table responsive>
                      <thead className="text-black">
                        <tr>
                          <th>
                            <strong>NAME</strong>
                          </th>
                          <th>
                            <strong>EMAIL</strong>
                          </th>
                          <th>
                            <strong>PHONE NUMBER</strong>
                          </th>
                          <th>
                            <strong>Created On</strong>
                          </th>
                          <th>
                            <strong>STATUS</strong>
                          </th>
                          <th>
                            <strong>ACTION</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {users?.map((item, i) => (
                          <tr key={i}>
                            <td>{item?.name}</td>
                            <td>{item?.email}</td>
                            <td>
                              {" "}
                              {item?.countryCode ? "+" + item?.countryCode : ""}
                              {item?.number}
                            </td>
                            <td>
                              {moment(item?.createdAt).format("DD/MM/YYYY")}
                            </td>

                            <td>
                              {item?.isBlocked ? (
                                <Badge variant="danger light">Inactive</Badge>
                              ) : (
                                <Badge variant="success light">Active</Badge>
                              )}
                            </td>
                            <td>
                              <Dropdown>
                                <Dropdown.Toggle
                                  variant="warning light"
                                  className="light sharp btn btn-warning i-false"
                                >
                                  {svg1}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => onEdit(item)}>
                                    Edit
                                  </Dropdown.Item>
                                  {item?.isBlocked ? (
                                    <Dropdown.Item
                                      onClick={() => onAction(item?._id)}
                                    >
                                      Active
                                    </Dropdown.Item>
                                  ) : (
                                    <Dropdown.Item
                                      onClick={() => onAction(item?._id)}
                                    >
                                      Inactive
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                  {users?.length === 0 && !loader ? (
                    <div className="justify-content-center d-flex my-5 ">
                      Sorry, Data Not Found!
                    </div>
                  ) : (
                    ""
                  )}
                  {users?.length !== 0 && (
                    <div className="card-footer clearfix">
                      <div className="d-block d-sm-flex justify-content-between align-items-center">
                        <div className="dataTables_info">
                          Showing {currentPage * limit + 1} to{" "}
                          {listLength > (currentPage + 1) * limit
                            ? (currentPage + 1) * limit
                            : listLength}{" "}
                          of {listLength} entries
                        </div>
                        <Pagination
                          pageCount={pageCount}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      {showModal && (
        <EditUser
          show={showModal}
          data={oneUserData}
          table={getTableData}
          onHide={() => setShowModal(false)}
        />
      )}
    </div>
  );
}
